h1, h2, p {
  font-family: ubuntu;
}

@font-face {
  font-family: ubuntu;
  src: url(fonts/Ubuntu-Regular.ttf);
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}

/* Sliding Menu */
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Show the hand cursor on hover */
.showHandCursor {
  cursor: pointer;
  cursor: hand;
}

/* Grow effect on hover*/
.grow {
  transition: all .2s ease-in-out;
}
.grow:hover { 
  transform: scale(1.1); 
}

/* Hide an element */
.hidden {
  visibility: hidden;
}

/* Game description */
/**
 * Game description style
 */
.game-banner {
  width: 100%;
  max-height:130px;
}
.game-view-description-engine {
  max-width: 150px;
  float: left;
  margin: 0px 15px 0px 15px;
  text-align: center;
}
.game-view-description-engine p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
}

/**
 * Site panel styles
 */
.panel {
  background-color: #339966;
  margin-bottom: 10px;
  padding: 10px;
}
.panel-header {
  background-color: #339966;
  filter: brightness(0.8); 
  padding: 10px;
}
.round-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.round-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.bottom-ridge {
  border-bottom: #339966;
  border-bottom-width: 5px;
  border-bottom-style: outset;
}

/**
 * Major site sections
 * Header, game menu, body, footer
 */
html, body, #root {
  height: 100%;
}

.site-header {
  height: 50px;
  background-color: #339966;

  /* Makes the game selector layer above */
  position: absolute;

  /* Bottom ridge */
  border-bottom: #339966;
  border-bottom-width: 5px;
  border-bottom-style: outset;
}

.game-menu-item {
  margin-right: 10px;
  filter:brightness(0.8);
}
.game-menu-item:hover {
  filter: brightness(1);
}
.last {
  margin-right: 0px;
}

.sliding-menu-arrow:hover {
  filter: invert(0.7);
}

body {
  background-color: #b3ffb3 !important;
}

#header-and-body {
  min-height: 100%;
  padding-bottom: 30px;
}

.site-body {
  max-width: 950px !important;
  position: relative;
  padding-right: 0px !important;
  padding-left: 0px !important;
  /*background-color: #ccffcc;*/
}

.site-footer {
  height:30px;
  margin-top: -30px;
  background-color: #339966;

  border-top: #339966;
  border-top-width: 5px;
  border-top-style: inset;
}